import React from "react";


export const Header = () => {
  return (
    <header>
      <div className="relative bg-dots">
        <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/" className="flex items-cente">
              <span className="font-serif text-xl lg:text-3xl font-extrabold tracking-tight">
                WP_crypto_tools
                {/* <span className="text-gray-300">.com</span> */}
                <span className="italic font-light text-gray-600"></span>
              </span>
            </a>
          </div>

          <nav as="nav" className="hidden md:flex space-x-10 font-serif">
            <a
              href="/#products"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Products & Features
            </a>
            {/* <a
              href="/#features"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Features
            </a> */}
            <a
              href="/join"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Join Early Members
            </a>
          </nav>

          <div className="md:flex items-center justify-end md:flex-1 lg:w-0 font-serif">
            <a
              href="/suggest-feature"
              className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Suggest
            </a>
            <a
              href="/contact"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-sky-600 to-blue-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-sky-700 hover:to-blue-700 btn"
            >
              Contact
            </a>
          </div>
        </div>
        <div className=" bg-blue-600 py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
        <a
              href="/#products"
              className="text-base font-medium text-white hover:text-indigo-50"
            >
              Products & Features
            </a>
            <a
              href="/join"
              className="text-base font-medium text-white hover:text-indigo-50"
            >
              Join Early Members
            </a>
        </div>
      </div>
    </header>
  )
}
